import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`In addition to being able to access a free electronic copy of the book Developing Writers, you can also access some of the data that the authors analyzed in composing the book.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.fulcrum.org/concern/monographs/ww72bc25j?locale=en"}}>{`To view the free ebook and all of our publicly-available resources, click here. `}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Students, instructors, and researchers can benefit from interacting with these primary sources. This guide is intended to help you navigate the `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.fulcrum.org/"}}>{`UM Press Fulcrum platform`}</MDXTag>{` that houses the materials so that you can quickly and efficiently locate what interests you.`}</MDXTag>
<MDXTag name="h2" components={components}>{`What kinds of resources are available?`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Entry and exit interview transcripts`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Writing samples`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Audio interview clips with students from the study`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Audio and video interviews with authors of the book`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Audio and video interviews with students from the study`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Screencasts of students’ eportfolios`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Raw survey data`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`And more!`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Note that you can download all of the interviews, writing samples, and/or eportfolios at once on `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://deepblue.lib.umich.edu/data/catalog?utf8=%E2%9C%93&search_field=all_fields&q=Developing+Writers"}}>{`Deep Blue, the University of Michigan’s long-term data repository.`}</MDXTag></MDXTag>
<MDXTag name="h2" components={components}>{`What’s the quickest, easiest way to access data from the study?`}</MDXTag>
<MDXTag name="p" components={components}>{`The quickest way to access the data is through the search bar. `}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"https://www.fulcrum.org/concern/monographs/ww72bc25j?locale=en"}}>{`Go to the Developing Writers ebook site`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Scroll down until you see the tabs “Table of Contents,” “Resources,” and “Stats”`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Make sure you’re on the tab that says “Resources” `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`On the left side under “Search and Filter Resources” is a box that says "search resources" where you can type in anything you’re interested in: interviews, writing samples, eportfolios, etc. `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`For more information about how to refine your searches, keep reading`}</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components}>{`I’m interested in browsing through the data to see what’s there. Where is the data stored, and how do I start looking?`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"https://www.fulcrum.org/concern/monographs/ww72bc25j?locale=en"}}>{`Go to the Developing Writers ebook site`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Scroll down until you see the tabs “Table of Contents,” “Resources,” and “Stats”`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Make sure you’re on the tab that says “Resources” `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Use the boxes on the left side of the screen (“Keyword,” “Format,” etc.) to narrow results and get a sense of what’s available`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`For detailed directions about how to search for particular items, keep reading`}</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components}>{`How can I find interview transcripts?`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.fulcrum.org/concern/monographs/ww72bc25j?utf8=%E2%9C%93&locale=en&q=interviews"}}>{`Click here to see all interview transcripts.`}</MDXTag></MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Scroll down until you see “Table of Contents,” “Resources,” and “Stats”`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Make sure you’re in the “Resources” tab`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Under “Search and Filter Resources,” click on the “Keyword” box (NOTE: you may need to click on a small gray box with three horizontal lines to see “Keyword”)`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Click “interview” `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`You can then click on “minor” to see all interviews with writing minors, and “entry” or “exit” to view only those interviews`}</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components}>{`What about audio files of interviews with students from the study or with authors of the book?`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.fulcrum.org/concern/monographs/ww72bc25j?f%5Bresource_type_sim%5D%5B%5D=audio&locale=en&q=audio"}}>{`Click here to see all audio files.`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`We have a few short audio clips available for you to listen to so that you can get an idea of what our interviews were like and hear from students firsthand. Also, some interviews with authors of the book were done via audio recording. `}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Scroll down until you see “Table of Contents,” “Resources,” and “Stats”`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Make sure you’re in the “Resources” tab`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Under “Search and Filter Resources,” click on the “Format” box (NOTE: you may need to click on a small gray box with three horizontal lines to see “Format”)`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Click on “audio” `}</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components}>{`How can I find writing samples?`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.fulcrum.org/concern/monographs/ww72bc25j?locale=en&q=writing+samples"}}>{`Click here to see all writing samples.`}</MDXTag></MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Scroll down until you see “Table of Contents,” “Resources,” and “Stats”`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Make sure you’re in the “Resources” tab`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Under “Search and Filter Resources,” click on the “Keyword” box (NOTE: you may need to click on a small gray box with three horizontal lines to see “Keyword”)`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Click “writing sample” `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Note that you can filter further by clicking on items like “humanities,” “upper division,” “lower division,” etc. `}</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components}>{`How can I find a writing sample or an interview from a particular student?`}</MDXTag>
<MDXTag name="p" components={components}>{`In addition to being organized by data type, the data is also organized by student pseudonym. `}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Scroll down until you see “Table of Contents,” “Resources,” and “Stats”`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Make sure you’re in the “Resources” tab`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Under “Search and Filter Resources,” click on the “Keyword” box (NOTE: you may need to click on a small gray box with three horizontal lines to see “Keyword”)`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Click the word “more” and a box will pop up`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Click on “A-Z sort” in the lower right-hand corner of the box, and you will see the list of student pseudonyms (note that the list goes on for more than one page, so you may need to click “Next” to find the student you’re looking for)`}</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components}>{`How can I find video interviews with students from the study or with authors of the book?`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.fulcrum.org/concern/monographs/ww72bc25j?utf8=%E2%9C%93&locale=en&q=video"}}>{`Click here to see all video files.`}</MDXTag></MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Scroll down until you see “Table of Contents,” “Resources,” and “Stats”`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Make sure you’re in the “Resources” tab`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Under “Search and Filter Resources,” click on the “Format” box (NOTE: you may need to click on a small gray box with three horizontal lines to see “Format”)`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Select “video”`}</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components}>{`I’m interested in students’ eportfolios. How can I find them?`}</MDXTag>
<MDXTag name="p" components={components}>{`TO VIEW SCREENCASTS:`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.fulcrum.org/concern/monographs/ww72bc25j?utf8=%E2%9C%93&f%5Bkeywords_sim%5D%5B%5D=eportfolio&f%5Bresource_type_sim%5D%5B%5D=video&locale=en&q=screencast"}}>{`Click here to see screencasts of all eportfolios.`}</MDXTag></MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Scroll down until you see “Table of Contents,” “Resources,” and “Stats”`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Make sure you’re in the “Resources” tab`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Under “Search and Filter Resources,” click on the “Format” box (NOTE: you may need to click on a small gray box with three horizontal lines to see “Format”)`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Click “video”`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Click on the box that says “Keyword” and click “eportfolios” `}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`TO DOWNLOAD FULL VERSIONS OF STUDENTS’ EPORTFOLIOS:`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://deepblue.lib.umich.edu/data/catalog?utf8=%E2%9C%93&search_field=all_fields&q=Developing+Writers"}}>{`Go to Deep Blue,`}</MDXTag>{` the University of Michigan’s long-term data repository. `}</MDXTag>
<MDXTag name="h2" components={components}>{`How can I find survey data?`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.fulcrum.org/concern/monographs/ww72bc25j?utf8=%E2%9C%93&f%5Bkeywords_sim%5D%5B%5D=survey&q=survey"}}>{`Click here to see the .csv file with the raw survey data`}</MDXTag>{`, as well as all survey questions.`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Scroll down until you see “Table of Contents,” “Resources,” and “Stats”`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Make sure you’re in the “Resources” tab`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Under “Search and Filter Resources,” click on the “Keyword” box (NOTE: you may need to click on a small gray box next to “Search and Filter Resources” to see “Keyword”)`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Under “Keyword,” click “more”`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`In the bottom right corner of the box that pops up, click the button that says “A-Z Sort” `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Keep clicking the “next” button until you find the term “survey.” You will need to go through the first alphabet of capital letters and then find survey in the second alphabet of lowercase letters.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Click “survey”`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"About the Data","key":"about-the-data","parentKey":"about"};

  